import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './core-components/redirect/redirect.component';
import { AdminGuard, AuthGuard } from './guards';

const routes: Routes = [
  { path: '', component: RedirectComponent },
  {
    path: 'dash-v2',
    loadChildren: () =>
      import('./pages/dash-v2/dash-v2.module').then((m) => m.DashV2Module),
  },
  {
    path: 'dash-v2-redirect',
    loadChildren: () =>
      import('./pages/dash-v2-redirector/dash-v2-redirector.module').then(
        (m) => m.DashV2RedirectorModule,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@pages/auth/auth.module').then((m) => m.AuthModule),
  },
  // This component is used to map legacy "Dashboards" routes to "dash". Because angular doesn't support
  // wildcards in the middle of the route we use route params instead. Adding just "/:x/:y/:z" doesn't work
  // so we need a separate route for each child route level
  {
    path: 'dashboards',
    loadChildren: () =>
      import('@pages/dash-list/dash-list.module').then((m) => m.DashListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'share-links',
    loadChildren: () =>
      import('@pages/public-link-manager/public-link-manager.module').then(
        (m) => m.PublicLinkManagerModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('@pages/user-management/user-management.module').then(
        (m) => m.UserManagementModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-profile',
    loadChildren: () =>
      import('@pages/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('@pages/search/search.module').then((m) => m.SearchModule),
    data: { title: 'Search' },
    canActivate: [AuthGuard],
  },
  {
    path: 'sync-agents',
    loadChildren: () =>
      import('@pages/sync-agents/sync-agents.module').then(
        (m) => m.SyncAgentsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'resplendent-api',
    loadChildren: () =>
      import(
        '@pages/resplendent-api-key-manager/resplendent-api-key-manager.module'
      ).then((m) => m.ResplendentApiKeyManagerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tier-features',
    loadChildren: () =>
      import(
        '@pages/feature-item-selector-page/feature-item-selector-page.module'
      ).then((m) => m.FeatureItemSelectorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('@pages/subscription/subscription.module').then(
        (m) => m.SubscriptionModule,
      ),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'manage-tags',
    loadChildren: () =>
      import('@pages/manage-tags/manage-tags.module').then(
        (m) => m.ManageTagsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'template-gallery',
    loadChildren: () =>
      import('./pages/template-gallery/template-gallery.module').then(
        (m) => m.TemplateGalleryModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-soundboard',
    loadChildren: () =>
      import('@pages/manage-soundboard/manage-soundboard.module').then(
        (m) => m.ManageSoundboardModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dynamic-filter-variables',
    loadChildren: () =>
      import(
        '@pages/dynamic-filter-variable/dynamic-filter-variable.module'
      ).then((m) => m.DynamicFilterVariableModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-dashboard-groups',
    loadChildren: () =>
      import(
        '@pages/manage-dashboard-groups/manage-dashboard-groups.module'
      ).then((m) => m.ManageDashboardGroupsModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'data-management',
    loadChildren: () =>
      import('@pages/data-management/data-management.module').then(
        (m) => m.DataManagementModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('@pages/oauth/oauth.module').then((m) => m.OauthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'audit-log',
    loadChildren: () =>
      import('@pages/audit-log/audit-log.module').then((m) => m.AuditLogModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('@root/pages/four-o-four/four-o-four.module').then(
        (m) => m.FourOFourModule,
      ),
  },
];

const config: ExtraOptions = {
  useHash: false,
  // scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
