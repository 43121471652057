import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IDashboardInfo, INotification, ThemeOption } from '@interfaces';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { Store } from '@ngxs/store';
import { AppComponent } from '@root/app.component';
import { BaseComponent } from '@root/core-components/base-component';
import { notificationRoutes } from '@root/custom_scripts/config';
import { UserFeedbackService } from '@root/services/user-feedback.service';
import { IAppStateModel } from '@root/state/app.model';
import {
  AuthService,
  EventQueueService,
  StateService,
  WebsocketService,
} from '@services';
import { formatDistanceStrict } from 'date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  BehaviorSubject,
  combineLatest,
  map,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { ImpersonationDialogComponent } from './impersonation-dialog/impersonation-dialog.component';
import { MENU_ITEMS } from './side-menu-items';

@Component({
  selector: 'resplendent-general-layout',
  templateUrl: './general-layout.component.html',
  styleUrls: ['./general-layout.component.scss'],
  animations: [
    trigger('animateRoute', [
      transition('* <=> *', [
        group([
          query(
            ':enter',
            [
              style({
                opacity: 0,
                transform: 'translateY(9rem) rotate(-10deg)',
              }),
              animate(
                '0.35s cubic-bezier(0, 1.8, 1, 1.8)',
                style({ opacity: 1, transform: 'translateY(0) rotate(0)' }),
              ),
              animateChild(),
            ],
            { optional: true },
          ),
          query(
            ':leave',
            [animate('0.35s', style({ opacity: 0 })), animateChild()],
            { optional: true },
          ),
        ]),
      ]),
    ]),
  ],
})
export class GeneralLayoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  menuItems: NbMenuItem[] = MENU_ITEMS;
  sessionVariables$ = this.stateService.sessionVariables$;
  subSideMenuOpen: boolean = false;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  userPictureOnly: boolean = false;
  currentTheme = 'default';
  themeOptions: ThemeOption[] = [
    { value: 'default', label: 'Light' },
    { value: 'dark', label: 'Dark' },
    { value: 'cosmic', label: 'Cosmic' },
  ];
  user: { name: string; picture: string; loggedIn: boolean } = {
    name: 'Loading...',
    picture: '',
    loggedIn: false,
  };
  showImpersonationMenu: boolean = false;

  iconSelector = {
    success: {
      icon: 'checkmark-circle-outline',
      status: 'success',
      nbSpinner: false,
    },
    error: { icon: 'alert-circle-outline', status: 'danger', nbSpinner: false },
  };
  notificationRoutes = notificationRoutes;

  stripPadding = false;
  headerMessage = '';
  trialExpTimestamp = null;

  feedbackFormGroup = new FormGroup({
    feedback: new FormControl('', [Validators.required]),
  });
  feedbackSubmitted = false;
  fullScreen$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.frontEndState.fullScreen,
  );
  impersonating$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.impersonatingCustomerId,
  );
  impersonatingOptions$ = this.store
    .select(
      (state) => (state.app as IAppStateModel).appState.impersonationOptions,
    )
    .pipe(
      map((options) =>
        options.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur.name }),
          {} as Record<string, any>,
        ),
      ),
    );
  notificationStatusSource$ = new BehaviorSubject<
    'number' | 'error' | 'success' | 'loading'
  >('number');
  notificationStatus$ = this.notificationStatusSource$.asObservable();
  notificationCountSource$ = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSource$.asObservable();

  private deviceDetector: DeviceDetectorService = inject(DeviceDetectorService);
  isMobile = this.deviceDetector.isMobile();

  constructor(
    private stateService: StateService,
    private sidebarService: NbSidebarService,
    private appComponent: AppComponent,
    private breakpointService: NbMediaBreakpointsService,
    private themeService: NbThemeService,
    private nbMenuService: NbMenuService,
    private eventQueue: EventQueueService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private websocketService: WebsocketService,
    private userFeedbackService: UserFeedbackService,
    private store: Store,
    private dialog: NbDialogService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    const clock$ = timer(1000, 10000);
    const sessionVars$ = this.sessionVariables$;
    combineLatest([clock$, sessionVars$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([, sessionVariables]) => {
        this.user.picture = sessionVariables.userPreferences.avatar;
        this.user.name = sessionVariables.user.name;
        this.user.loggedIn = this.authService.isAuthenticated;
        this.showImpersonationMenu =
          (sessionVariables.user.is_reseller &&
            sessionVariables.user.role === 'admin') ||
          sessionVariables.user.is_giga_admin;
        // Display trial expiration
        let exp_timestamp = sessionVariables.user.trial_expiration;
        if (exp_timestamp) {
          // exp_timestamp = exp_timestamp - 259200;
        }
        if (exp_timestamp) {
          const now = Date.now() / 1000;
          if (exp_timestamp < now) this.headerMessage = 'Trial Expired';
          if (exp_timestamp >= now) {
            const expires_in = formatDistanceStrict(
              exp_timestamp * 1000,
              now * 1000,
            );
            this.headerMessage = `Trial ends in ${expires_in}`;
          }
        } else {
          this.headerMessage = null;
          this.trialExpTimestamp = null;
        }

        const dashboards = this.store.selectSnapshot(
          (state) => (state.app as IAppStateModel).appState,
        ).allDashboards.dashboards;

        // Build menu items for recent dashboards
        if (
          sessionVariables.RecentDashboards &&
          Object.keys(dashboards).length > 0
        ) {
          // Since sessionVariables.RecentDashboards is an array, we need to map it to an
          // IDashboardsInfos object
          let recentDashboardInfos: { [key: string]: IDashboardInfo } = {};
          sessionVariables.RecentDashboards.forEach((uuid) => {
            recentDashboardInfos[uuid] = dashboards.find((d) => d.id === uuid);
          });
          // Add the recent dashboards to the last 5 items in the dashboards menu
          // since the first item is the 'All Dashboards' link
          const recentDashMenuItems =
            this.buildDashboardMenuItemChildren(recentDashboardInfos);
          if (recentDashMenuItems) {
            this.menuItems[1].children = [...recentDashMenuItems];
          }
        }
        // Ditch the reseller section for no resellers
        if (sessionVariables.user.role === 'guest') {
          this.menuItems = this.menuItems.filter(
            (i) => i.title === 'Dashboards',
          );
        } else if (sessionVariables.user.role === 'member') {
          this.menuItems = this.menuItems.filter(
            (i) => i.title === 'Dashboards' || i.title === 'Recent Dashboards',
          );
        } else if (sessionVariables.user.role === 'staff') {
          const adminOnlyItems = ['Resplendent API', 'Template Gallery'];
          this.menuItems = this.menuItems.filter((i) => i.title !== 'Company');
          this.menuItems.forEach((item, i) => {
            item.children = item.children?.filter(
              (child) => !adminOnlyItems.includes(child.title),
            );
          });
        }
        if (!sessionVariables.user.is_reseller) {
          this.menuItems = this.menuItems.filter(
            (i) => i.title !== 'Reseller Portal',
          );
        }
      });

    let sessionVars = this.stateService.getSessionVariables();
    sessionVars.sideMenuItems = MENU_ITEMS;

    // Set the menu items in the session variables
    this.stateService.setSessionVariables(sessionVars);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.isDestroyed$),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.isDestroyed$),
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.nbMenuService
      .onItemClick()
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((item) => {
          if (this.isMobile && item.tag === 'menu') {
            this.sidebarService.collapse('app-sidebar');
          }
        }),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
        if (title === 'Log out') {
          this.appComponent.logOut();
        } else if (title === 'Profile') {
          this.router.navigate(['/user-profile']);
        }
      });

    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const route = event.url;
        if (route.includes('/dash/')) {
          this.stripPadding = true;
        } else {
          this.stripPadding = false;
        }
      } else {
        return;
      }
    });

    this.stateService.sessionVariables$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((sessionVariables) => {
        this.notificationCountSource$.next(
          sessionVariables.notifications.length,
        );
      });

    this.stateService.sessionVariables$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap(() => this.notificationStatusPreview()),
      )
      .subscribe();
  }

  notificationStatusPreview() {
    const { notifications } = this.stateService.getSessionVariables();
    if (notifications.length > 0) {
      let now = Date.now();
      if (now - notifications[0].epoch_timestamp < 1000 * 30) {
        // After 30 seconds, the notification status will be 'number'
        setTimeout(() => {
          this.notificationStatusSource$.next('number');
        }, 30000);
        this.notificationStatusSource$.next(notifications[0].status);
      }
    } else {
      this.notificationStatusSource$.next('number');
    }
    this.notificationCountSource$.next(notifications.length);
  }

  buildDashboardMenuItemChildren(dashboards: {
    [key: string]: IDashboardInfo;
  }): NbMenuItem[] {
    let dashboardMenuItemChildren: NbMenuItem[] = [];
    if (dashboards) {
      Object.keys(dashboards).forEach((uuid) => {
        const dash = dashboards[uuid];
        if (dash) {
          const link = `/dash-v2-redirect/${uuid}`;
          const dashboardMenuItem = {
            title: this.limitTextLength(dashboards[uuid].title, 25),
            link,
          };
          dashboardMenuItemChildren.push(dashboardMenuItem);
        }
      });
      return dashboardMenuItemChildren;
    }
  }

  private limitTextLength(text: string, maxLength: number): string {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  }

  public toggleSidebar() {
    this.sidebarService.toggle(false, 'app-sidebar');
  }

  async setTheme(theme: ThemeOption) {
    this.eventQueue.dispatch('SET_THEME', theme);
  }

  logUser() {
    console.log(this.stateService.sessionVariables.user);
  }
  async deleteNotification(notification: INotification) {
    if (notification.dismissible) {
      const { res, success } = await this.websocketService.asyncRequest(
        'DELETE_NOTIFICATIONS',
        [notification.id],
      );
      if (success) {
        const notifs = this.stateService.sessionVariables.notifications;
        const i = notifs.findIndex((n) => n.id === notification.id);
        notifs.splice(i, 1);
      }
    }
  }

  submitFeedback() {
    if (this.feedbackFormGroup.invalid) return;
    this.userFeedbackService.submitFeedback(
      this.feedbackFormGroup.get('feedback').value,
      this.stateService.sessionVariables.user.name,
      this.stateService.sessionVariables.user.email,
    );
    this.feedbackSubmitted = true;

    setTimeout(() => {
      this.feedbackFormGroup.reset();
    }, 1000);
    setTimeout(() => {
      this.feedbackSubmitted = false;
    }, 10000);
  }

  openImpersonationDialog() {
    this.dialog.open(ImpersonationDialogComponent);
  }
}
