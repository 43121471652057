import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { navToSignIn } from '@root/helpers/route-helpers';
import { App } from '@root/state/app.actions';
import { AuthService, StateService } from '@services';
import { lastValueFrom, take, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';

/**
 * Defines the possible types of redirects that can be performed
 */
type RedirectType =
  | 'dashList' // Redirect to dashboard list
  | 'recentDashboard' // Redirect to most recent dashboard
  | 'integrations' // Redirect to integrations page
  | 'datasets'; // Redirect to datasets page

/**
 * Configuration interface for defining redirect behavior
 */
interface RedirectConfig {
  /** The route path segments to navigate to */
  path: string[];
  /** Optional condition that must be met before redirect can occur */
  condition?: () => Promise<boolean>;
}
/**
 * Component that handles automatic redirects based on application state and user context.
 * Acts as a routing middleware to direct users to appropriate pages based on their
 * authentication status, recent activity, and available data.
 *
 * @example
 * Navigate to this component with a query param:
 * ```
 * router.navigate(['/'], { queryParams: { redirectType: 'dashList' } });
 * ```
 */
@Component({
  selector: 'resplendent-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent extends BaseComponent implements OnInit {
  /**
   * Configuration map for different redirect types.
   * Each type specifies its target path and optional condition for redirect.
   */
  private readonly redirectConfigs: Record<RedirectType, RedirectConfig> = {
    recentDashboard: {
      path: ['dash-v2'],
      condition: async () => {
        await this.stateService.loadUserPreferences();
        return !!this.stateService.sessionVariables?.RecentDashboards[0];
      },
    },
    dashList: {
      path: ['/dashboards'],
      condition: async () => {
        const dataStreams = await this.fetchDataStreams();
        return Object.keys(dataStreams.dataStreams).length > 0;
      },
    },
    integrations: {
      path: ['/data-management/integrations'],
      condition: async () => {
        const integrations = await this.fetchIntegrations();
        return !integrations.dataSources.some(
          (ds) => ds.connections.length > 0,
        );
      },
    },
    datasets: {
      path: ['/data-management/datasets'],
    },
  };
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private store: Store,
    private actions$: Actions,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.detectRedirectParam();
  }
  /**
   * Subscribes to route query params to detect redirect requests
   */
  private detectRedirectParam() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((params) => {
          this.pickRedirectAction(params);
        }),
      )
      .subscribe();
  }

  /**
   * Determines and executes the appropriate redirect based on query parameters
   * @param params - Route query parameters
   */
  private async pickRedirectAction(params: Params): Promise<void> {
    try {
      this.startLoading();

      const redirectType = await this.determineRedirectType(
        params.redirectType,
      );
      if (!redirectType) return;

      const config = this.redirectConfigs[redirectType];
      if (!config) {
        throw new Error(`Unknown redirectType "${redirectType}"`);
      }

      const path = [...config.path];
      if (redirectType === 'recentDashboard') {
        path.push(this.stateService.sessionVariables?.RecentDashboards[0]);
      }

      await this.router.navigate(path);
    } catch (error) {
      console.error('Redirect failed:', error);
      throw error;
    } finally {
      this.stopLoading();
    }
  }

  /**
   * Determines the appropriate redirect type based on application state and user context
   * @param initialType - Optional initial redirect type from query params
   * @returns Promise resolving to the determined redirect type or null if redirect should not proceed
   */
  private async determineRedirectType(
    initialType?: RedirectType,
  ): Promise<RedirectType | null> {
    let proceed = true;

    proceed = await this.checkAuthentication();
    if (!proceed) return null;

    proceed = await this.checkEmailVerification();
    if (!proceed) return null;

    if (initialType) return initialType;

    // Try each redirect type in priority order
    const priorityOrder: RedirectType[] = [
      'recentDashboard',
      'integrations',
      'datasets',
      'dashList',
    ];

    for (const type of priorityOrder) {
      const config = this.redirectConfigs[type];
      if (!config.condition || (await config.condition())) {
        return type;
      }
    }

    return 'dashList'; // fallback
  }

  /**
   * Checks if user is authenticated, redirects to sign-in if not
   */
  private async checkAuthentication(): Promise<boolean> {
    if (!this.authService.isAuthenticated) {
      await navToSignIn(this.router);
      return false;
    }
    return true;
  }

  /**
   * Checks if user's email is verified, redirects to verification page if not
   */
  private async checkEmailVerification(): Promise<boolean> {
    if (this.authService.isEmailVerified === false) {
      await this.router.navigate(['/auth/verify-email']);
      return false;
    }
    return true;
  }

  /**
   * Fetches integration data from the store
   * @returns Promise resolving to integration data
   */
  private async fetchIntegrations(): Promise<App.FetchDataSourcesSuccess> {
    this.store.dispatch(new App.FetchDataSources());
    return lastValueFrom(
      this.actions$.pipe(
        ofActionSuccessful(App.FetchDataSourcesSuccess),
        take(1),
      ),
    );
  }

  /**
   * Fetches data streams from the store
   * @returns Promise resolving to data streams
   */
  private async fetchDataStreams(): Promise<App.FetchDataStreamsSuccess> {
    this.store.dispatch(new App.FetchDataStreams(false));
    return lastValueFrom(
      this.actions$.pipe(
        ofActionSuccessful(App.FetchDataStreamsSuccess),
        take(1),
      ),
    );
  }
}
